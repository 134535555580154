import React from "react";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Hero from "common/hero";
import Seo from "common/seo";
import CategoryDetails from "common/category-details";
import { TypeA, TypeC } from "common/callouts";

import heroImageRef from "images/face/immersivehydrationtechnology/Face-Web-Page-Banner-Molecules.webp";
import productInfoImg from "images/face/faceproducts/face-products.webp";

import img1 from "images/face/faceproducts/Cleansing_Gel.webp";
import img2 from "images/face/faceproducts/Daily Lotion_NEW.webp";
import img3 from "images/face/faceproducts/Night Cream_NEW.webp";
import img4 from "images/face/faceproducts/moisture-boost-serum.webp";
import img5 from "images/face/faceproducts/Serum-copy_NEW.webp"

import img6 from "images/callouts/eucerin-face-product-family.webp";
import img7 from "images/callouts/cout-footer-daily-skin.webp";
//import img8 from "images/callouts/cout-footer-facecare.webp";
import img8 from "images/callouts/sunproducts2x.webp";
import "./eucerinfaceproducts.scss";

const MyHero = () => <Hero className="inner-hero" bgImagePath={heroImageRef} />;

function IndexPage({ pageContext }) {
  return (
    <Layout
      pageClass="page-face-eucerinsunproducts"
      breadcrumbData={pageContext.breadcrumb}
      hero={<MyHero />}
    >
      <Seo pageTitle="Eucerin Face Products" productDescription="" />
      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <CategoryDetails
              headBgColor="#106E9E"
              headText="Eucerin Face Products"
              infoText="Eucerin<sup>&reg;</sup> Face, featuring <span class='font-bold-alt'>new</span> Immersive Hydration technology*"
              bodyText="<span class='font-bold'>Science meets elegance:</span> Eucerin Face utilizes Immersive Hydration technology— <br />
            multi-weight hyaluronic acid and antioxidant complexes in lightweight, elegant formulations.  "
              productInfoImg={productInfoImg}
              productInfoImgAlt="Products in routine with two additional ones to boost and enhance"
              imgHeader="&nbsp;"
              headContent={`<p class="references spacer">*Eucerin<sup>®</sup> Face Hydrating Cleansing Gel does not contain Immersive Hydration technology.</p>`}
            >
              <h5 className="oceanic-blue">
                A daily routine formulated for all skin types, even sensitive
                skin
              </h5>
              <p>
                This easy, day/night routine delivers lasting,
                <sup>&dagger;</sup> intense moisture for a visibly smoother and
                more radiant appearance.
              </p>
              <ul className="oceanic-bullets">
                <li>
                  <span>
                    <span className="font-bold">Cleanse: </span>Eucerin
                    <sup>®</sup> Face Hydrating Cleansing Gel
                  </span>
                </li>
                <li>
                  <span>
                    <span className="font-bold">Protect: </span>Eucerin
                    <sup>®</sup> Face Immersive Hydration Daily Lotion SPF 30
                  </span>
                </li>
                <li>
                  <span>
                    <span className="font-bold">Hydrate: </span>Eucerin
                    <sup>®</sup> Face Immersive Hydration Night Cream
                  </span>
                </li>
                <li>
                  <span>
                    <span className="font-bold">Boost and Enhance: </span>
                    Eucerin<sup>®</sup> Face Immersive Hydration Moisture Boost
                    Serum and Eucerin<sup>&reg;</sup> Face Immersive Hydration
                    Gel-Cream
                  </span>
                </li>
              </ul>
              <p className="references">
                <sup>&dagger;</sup>For sun protection with Daily Lotion, use as
                directed.
              </p>
            </CategoryDetails>
          </section>
        </div>
        <section className="callouts-container">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={8} lg={4} order={{ xs: 2, lg: 1 }}>
                <TypeC
                  head1={`Eucerin<sup>&reg;</sup> Face Hydrating Cleansing Gel`}
                  head1Css="oceanic-blue"
                  buttonText="See product information"
                  buttonUrl="/face/eucerinfaceproducts/cleasinggel"
                  className="center-v"
                >
                  <p className="callout-min">
                    Gentle, effective face cleanser, enriched with hyaluronic
                    acid, helps support skin’s moisture balance.
                  </p>
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 1, lg: 2 }}>
                <img
                  src={img1}
                  alt="Bottle of Eucerin Face Hydration Cleansing Gel"
                  className="cout-product-img"
                />
              </Col>
              <Col sm={12} md={8} lg={4} order={{ xs: 4, lg: 3 }}>
                <TypeC
                  head1={`Eucerin<sup>&reg;</sup> Face Immersive Hydration Daily Lotion SPF 30`}
                  head1Css="oceanic-blue"
                  buttonText="See product information"
                  buttonUrl="/face/eucerinfaceproducts/dailylotion"
                  className="center-v"
                >
                  <p>
                    With daily use, hydrates the face, provides broad-spectrum
                    sun protection SPF 30, and helps protect skin from premature
                    aging.<sup>&Dagger;</sup>
                  </p>
                  <br />
                  <p className="references-symbols">
                    <sup>&Dagger;</sup>Used as directed with other sun
                    protection measures (see Drug Facts Box).
                  </p>
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 3, lg: 4 }}>
                <img
                  src={img2}
                  alt="Bottle of Eucerin Face Immersive Hydration Daily Lotion"
                  className="cout-product-img"
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={8} lg={4} order={{ xs: 2, lg: 1 }}>
                <TypeC
                  head1={`Eucerin<sup>&reg;</sup> Face Immersive Hydration Night Cream`}
                  head1Css="oceanic-blue"
                  buttonText="See product information"
                  buttonUrl="/face/eucerinfaceproducts/nightcream"
                  className="center-v"
                >
                  <p>
                    With daily use, provides 24-hour moisture and a
                    well-hydrated, visibly renewed appearance.
                  </p>
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 1, lg: 2 }}>
                <img
                  src={img3}
                  alt="Bottle of Euceirn Face Immersive Hydration Night Cream"
                  className="cout-product-img"
                />
              </Col>
              <Col sm={12} md={8} lg={4} order={{ xs: 4, lg: 3 }}>
                <TypeC
                  head1={`Eucerin<sup>&reg;</sup> Face Immersive Hydration Moisture Boost Serum`}
                  head1Css="oceanic-blue"
                  buttonText="See product information"
                  buttonUrl="/face/eucerinfaceproducts/serum"
                  className="center-v"
                >
                  <p>
                    With daily use, skin looks more radiant and feels soft,
                    supple, and more firm.
                  </p>
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 3, lg: 4 }}>
                <img
                  src={img4}
                  alt="Bottle of Eucerin Face Immersive Hydration Serum"
                  className="cout-product-img"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={8} lg={4} order={{ xs: 2, lg: 1 }}>
                <TypeC
                  head1={`Eucerin<sup>&reg;</sup> Face Immersive Hydration Gel-Cream`}
                  head1Css="oceanic-blue"
                  buttonText="See product information"
                  buttonUrl="/face/eucerinfaceproducts/gelcream"
                  className="center-v"
                >
                  <p>
                    An alternative night cream, this ultralight formula provides
                    72-hour hydration and visibly improves the look of fine
                    lines.
                  </p>
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 3, lg: 4 }}>
                <img
                  src={img5}
                  alt="Jar of Eucerin Face Immersive Hydration Gel-Cream"
                  className="cout-product-img"
                />
              </Col>
            </Row>
          </div>
        </section>

        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img6}
                  imgAlt="Eucerin face family product line"
                  caption="Find resources to support your face care recommendations"
                  buttonText="BROWSE RESOURCES"
                  buttonUrl="/resources"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img7}
                  imgAlt="EXPLORE BODY CARE"
                  caption="Clinically proven therapeutic solutions for daily skin care"
                  buttonText="EXPLORE BODY CARE"
                  buttonUrl="/body"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img8}
                  imgAlt="EXPLORE SUN CARE"
                  caption=" Sun protection for everyone under the sun"
                  buttonText="EXPLORE SUN CARE"
                  buttonUrl="/sun"
                  imgStyles={{ width: 236 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default IndexPage;


// import frontImage from "images/face/faceproducts/Cleansing_Gel.webp";
// import backImage from "images/face/faceproducts/Cleansing_gel_back.webp";